import { useEffect, useState } from 'react'
import { useRoutes } from '../constants/routes'
import { floatingMenuId } from '../constants'
import { Context } from '../../_types'
import SharedMenu from './sharedMenu'

type Props = {
  context: Context
}
export default function FloatingMenu({ context }: Props) {
  const routes = useRoutes()
  const [visible, isVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      if (visible && currentScrollY < 105) {
        isVisible(false)
      }

      if (!visible && currentScrollY > 105) {
        isVisible(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [visible])

  return (
    <div
      id={floatingMenuId}
      className={`bg-white w-full fixed top-0 px-1 py-3 hidden flex-col justify-center items-center no-print md:block
        ${visible ? ' floating-menu-in mt-0 shadow-xl' : 'mt-[-128px]'}
      `}>
      <div className="flex justify-between items-center max-w-screen-xl w-full mx-auto">
        <div className="px-2">
          <a href={routes.home()}>
            <img
              src="/agilix-written.svg"
              width={80}
              height={30}
              alt="logo"
              data-cy="navigation.home"
            />
          </a>
        </div>

        <SharedMenu context={context} />
      </div>
    </div>
  )
}
